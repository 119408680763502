<template>
  <div class="t-input" :class="[{ 'is-invalid': state === false }]">
    <b-input v-model="innerValue" v-bind="bindings" v-on="listeners"></b-input>
    <clear-input-icon v-if="state === false" class="icon-clear" @click="innerValue = ''" />
  </div>
</template>

<script>
import ClearInputIcon from 'assets/clear-input.svg?inline'

export default {
  name: 'TInput',

  components: {
    ClearInputIcon
  },

  inheritAttrs: false,

  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number, null]
    },
    state: {
      type: [Boolean, null],
      default: null
    }
  },

  computed: {
    innerValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    },

    bindings() {
      const { value, ...rest } = this.$attrs

      return {
        ...rest,
        state: this.state,
        class: this.$vnode.data.staticClass,
        style: this.$vnode.data.staticStyle
      }
    },

    listeners() {
      const { input, ...rest } = this.$listeners
      return rest
    }
  }
}
</script>

<style lang="scss" scoped>
.t-input {
  position: relative;

  .icon-clear {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
