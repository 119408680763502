import Cookies from 'js-cookie'

export function getUtmCookie(code) {
  const utm = Cookies.get(`${code}_utm`)
  let utmObject = {}
  if (utm) {
    try {
      utmObject = JSON.parse(utm)
    } catch (e) {
      console.error('Error parsing utm cookie', e)
    }
  }
  return utmObject
}

export function setUtmCookie(code, urlParams) {
  if (urlParams.utm_campaign || urlParams.utm_medium || urlParams.utm_source) {
    Cookies.set(
      `${code}_utm`,
      JSON.stringify({
        utm_campaign: urlParams.utm_campaign || '',
        utm_medium: urlParams.utm_medium || '',
        utm_source: urlParams.utm_source || ''
      }),
      { expires: 1 }
    )
  }
}
