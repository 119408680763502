import Vue from 'vue'
import { parseParamsURL, checkIsUnsubscribeEmailPath } from 'utilities/paramsFormat'
import API from 'api/fetch'
import { initURL, getSessionsTokenURL, loginWidgetSettingURL } from 'api/url'
import Membership from 'api/Membership'
import { getUserToken, setUserToken } from 'utilities/membership'
import { sendDataToParentWindow } from 'utilities/domOperation'
import i18n from '../i18n'
import _values from 'lodash/values'
import { initGtm, sendSetUserIdEvent } from 'utilities/analytics'
import { getTriplaUID } from 'utilities/triplaUID'
import paths from 'routes/paths'
import triplaLocalStorage from 'utilities/triplaLocalStorage'
import { appendChatbot } from 'utilities/chatbot'
import { setupTriplaBotLocalStorage } from 'src/helpers/triplabot'
import ERROR_CODES from 'constants/errorCode'
import { getSearchCriteria, removeSearchCriteria } from 'utilities/searchCriteriaCookie'
import {
  getClientSession,
  setClientSession,
  deleteClientSession
} from 'utilities/clientSessionCookie'
import Cookies from 'js-cookie'
import { NotBwRoutes } from 'constants/tripla'
import { setUtmCookie, getUtmCookie } from 'utilities/utmCookie'

export const getInitAndStartApp = (store, getVM = () => {}) => {
  const urlParams = parseParamsURL(window.location.search) // if we use history mode: window.location.search
  // vuex are all default null here
  const codeElem = document.querySelector('[data-triplabot-code]')
  const code =
    urlParams.hotel_code || urlParams.code || codeElem.getAttribute('data-triplabot-code')
  const fromChatbot = urlParams.fromChatbot || null
  const parentUrl = urlParams.parentUrl || ''
  const parentPathname = urlParams.parentPathname || null
  const hotelIdForMembershipModule = urlParams.hotel_id || null
  const metasearchSource = urlParams.metasearch_source || null
  const isInCBIframe = urlParams.iframe === 'triplabot'
  const segmentIds = urlParams.segment_ids
  const accessToken = urlParams.accessToken || urlParams.login_token
  const otid = urlParams.otid
  const isUnsubscribeEmailPath = checkIsUnsubscribeEmailPath(window.location.pathname)
  const oneTimeAccessToken = urlParams.one_time_access_token

  if (!code && !isUnsubscribeEmailPath) {
    return new Promise((resolve, reject) => reject(new Error('No hotel code')))
  }

  // remove tokens form query
  if (accessToken || oneTimeAccessToken) {
    const toRemove = ['login_token', 'accessToken', 'one_time_access_token']
    const regex = new RegExp(
      ['(\\?|&|%3F|%26)(?:', toRemove.join('|'), ')(?:=|%3D).*?(?:&|%26|$|(#)|(%23))'].join(''),
      'i'
    )
    history.replaceState(history.state, document.title, location.href.replace(regex, '$1$2'))
  }

  if (otid && code && Object.keys(urlParams).length === 2) {
    const searchCriteria = getSearchCriteria(code)
    if (searchCriteria) {
      location = `${location.href}${searchCriteria}`
      return Promise.resolve()
    }
  }

  store.dispatch('setting/setSetting', {
    code,
    parentUrl,
    parentPathname: decodeURIComponent(parentPathname)
  })
  store.dispatch('setting/setFromChatbot', fromChatbot)
  store.dispatch('setting/setIsInChatbotIframe', isInCBIframe)
  store.dispatch('setting/setChatbotAutoMessageAuthId', urlParams.auth_auto_message_id || null)
  store.dispatch('setting/setChatbotUserId', urlParams.triplabot_user_id)
  store.dispatch('setting/setHotelIdForMembershipModule', hotelIdForMembershipModule)
  store.dispatch('setting/setMetasearchSource', metasearchSource)
  setUtmCookie(code, urlParams)
  const utmObject = getUtmCookie(code)
  store.dispatch('setting/setUtmSource', {
    utm_campaign: urlParams.utm_campaign || utmObject?.utm_campaign || '',
    utm_medium: urlParams.utm_medium || utmObject?.utm_medium || '',
    utm_source: urlParams.utm_source || utmObject?.utm_source || '',
    campaignid: urlParams.campaignid,
    adgroupid: urlParams.adgroupid,
    keyword: urlParams.keyword,
    gclid: urlParams.gclid,
    yclid: urlParams.yclid
  })

  store.dispatch('customerSegment/setSegmentIds', segmentIds ? segmentIds.split(',') : null)
  store.dispatch('customerSegment/setIconMessageId', urlParams.icon_message_id)

  // if (urlParams.locale && _values(localeMapping).includes(urlParams.locale)) {
  //   i18n.locale = urlParams.locale
  //   triplaLocalStorage.setItem('triplabot_locale', urlParams.locale)
  // }

  // Keep the promise in a const, otherwise Google Analytics breaks
  let getSessionsToken = null
  getSessionsToken = getClientSession()
    ? Promise.resolve()
    : API.postData(
        getSessionsTokenURL(),
        {
          key: process.env.IDENTITY_KEY,
          secret: process.env.IDENTITY_SECRET
        },
        'auth'
      ).catch(() => {
        return null
      })

  return getSessionsToken
    .then((sessionRes) => {
      if (sessionRes) {
        setClientSession(sessionRes?.data?.client_session)
      }
      store.commit(
        'setting/setSessionToken',
        sessionRes?.data?.client_session || getClientSession()
      )
      if (isUnsubscribeEmailPath) {
        return
      }
      const initApp = API.getData(initURL())
      const getLoginWidgetSetting = API.getData(loginWidgetSettingURL())
      return Promise.all([
        initApp.catch((error) => {
          if (error.title === 'Invalid Client-Session') {
            // If client session is not valid
            deleteClientSession()
            location.reload()
          }
          return error
        }),
        getLoginWidgetSetting.catch((error) => {
          return error
        })
      ]).then(([initAppData, loginWidgetData]) => {
        if (initAppData.hotel_brand?.first_party_cookie_setting?.status === 'completed') {
          store.dispatch('setting/setSetting', {
            baseURL: `https://${initAppData.hotel_brand?.first_party_cookie_setting?.domain_name}`
          })
        }

        Vue.prototype.$preview =
          location.href.includes('tripla_preview=true') ||
          (process.env.NEW_DESIGN_BRANDS || '')
            .split(',')
            .includes(String(initAppData.booking_widget_setting_attributes.hotel_brand_id))
        store.dispatch('setting/setInit', {
          hotelBrand: initAppData.hotel_brand,
          bookLanguages: initAppData.book_languages,
          bookingWidgetSetting: initAppData.booking_widget_setting_attributes,
          chatbotSetting: initAppData.chatbot_setting,
          hotelBrandSetting: initAppData.hotel_brand_setting,
          paymentGatewaySetting: initAppData.payment_gateway_setting,
          mcpPaymentGatewaySetting: initAppData.mcp_payment_gateway_setting,
          integrations: initAppData.integrations,
          availableLanguages: initAppData.available_languages,
          paymentHubIntegrationType: initAppData.payment_hub_integration_type
        })
        store.dispatch(
          'setting/setDefaultSearch',
          initAppData.booking_widget_setting_attributes.default_search_setting
        )

        // handle locales start
        if (urlParams.locale && initAppData?.book_languages?.includes(urlParams.locale)) {
          i18n.locale = urlParams.locale
          triplaLocalStorage.setItem('triplabot_locale', urlParams.locale)
        }
        // handle locales end

        const vm = getVM()

        if (!vm.$urlContainsEmail) {
          initGtm()
        }

        store.commit('setting/setLoginWidgetData', loginWidgetData.data)

        if (accessToken) {
          setUserToken(accessToken)
        }

        // when BW is opened in a new tab, parent url is itself
        if (store.getters['setting/isOpenInNewTab'] && !isInCBIframe) {
          store.dispatch('setting/setSetting', {
            parentUrl: window.location.origin
          })
        }

        const hideChatbot = vm?.$route?.meta?.hideChatbot
        const isChatbotEnabledOnBW = store.getters['setting/isChatbotEnabledOnBW']

        if (
          !isInCBIframe &&
          !hideChatbot &&
          vm.$route.name !== paths.iframeToken &&
          isChatbotEnabledOnBW
        ) {
          const isOpenInNewTab = store.getters['setting/isOpenInNewTab']

          if (isOpenInNewTab) {
            const triplabotSession =
              Cookies.get(`triplabot_session_${store.getters['setting/getHotelId']}`) ||
              vm.$route.query.triplabot_session
            if (triplabotSession) {
              triplaLocalStorage.setItem('triplabot_session', triplabotSession)
            }

            setupTriplaBotLocalStorage(vm.$route.query)
            if (!NotBwRoutes.includes(vm.$route.name)) {
              appendChatbot(store.getters['setting/getHotelCode'])
            }

            // TODO: move to some where after init is done
            // vm.$router.replace({
            //   query: {
            //     ...vm.$route.query,
            //     triplabot_session: undefined,
            //     triplabot_minimized: undefined,
            //     closed_icon_messages: undefined
            //   }
            // })
          }

          sendDataToParentWindow({ action: 'show-chatbot' })
        }

        if (!isInCBIframe) {
          sendDataToParentWindow({ action: 'booking-widget-opened' })
        }

        const isOneTimeAccessTokenPresent = !!oneTimeAccessToken

        if (store.getters['setting/getCreansmaerd'].active && otid) {
          return Membership.creansmaerdLogin({ otid })
        } else if (isOneTimeAccessTokenPresent) {
          const nullParams = null
          const headers = { 'X-One-Time-Access-Token': oneTimeAccessToken }

          return Membership.getUserIdentity(nullParams, headers)
        } else if (store.getters['setting/isFirstPartyDomainEnable'] || getUserToken()) {
          return Membership.getUserIdentity()
        } else {
          return Promise.resolve()
        }
      })
    })
    .then((res) => {
      // identify as user account
      if (otid) {
        const vm = getVM()
        vm.$router.onReady(() => {
          vm.$router.replace({
            query: {
              ...vm.$route.query,
              otid: undefined
            }
          })
          removeSearchCriteria()
        })
      }
      const data = res?.data || res
      const isTriplabotSyncing =
        urlParams.triplabot_sync === 'true' &&
        store.getters['setting/isFacilitySignInEnabled'] &&
        store.getters['setting/isInChatbotIframe'] &&
        !store.getters['membership/isCorporateSignedIn']
      if (data?.session) {
        setUserToken(data?.session)
        sendSetUserIdEvent({ cookieID: getTriplaUID(), userID: data?.user?.id })
        store.dispatch('membership/setLoggedInUser', data)
        if (isTriplabotSyncing) {
          console.info(`[init] chat chatbot_session, token exists? ${!!getUserToken()}`)
          Membership.getChatbotSession(store.getters['setting/getHotelCode'], {
            user_id: store.getters['setting/getChatbotUserId']
          })
            .then((chatbotRes) => {
              if (!chatbotRes) return
              sendDataToParentWindow({
                action: 'receiveTriplabotSession',
                session: chatbotRes.data.session,
                isInCBIframe
              })
            })
            .finally(() => {
              sendDataToParentWindow({ action: 'triplabotSynced' })
            })
        }
        if (store.getters['membership/getCurrentUser'].program_id) {
          Promise.all([
            Membership.getSignedInProgram(
              store.getters['membership/getCurrentUser'].program_id
            ).catch((e) => {
              if (!store.getters['membership/isCorporateSignedIn']) {
                store.dispatch('membership/logoutLocally')
                store.dispatch('error/setGlobalError', i18n.t('base.logoutMessage'))
              }
              throw e
            }),
            Membership.getProgramByIds(
              store.getters['membership/getCurrentUser'].non_signed_in_program_ids
            )
          ])
            .then(([signedInProgram, nonSignedInPrograms]) => {
              if (store.getters['setting/isSupportCompanion']) {
                store.dispatch(
                  'membership/setCompanionInfo',
                  signedInProgram.data.additional_tiers_attributes
                )
              }
              store.dispatch('membership/setNonSignedInPrograms', nonSignedInPrograms)
              store.dispatch('membership/setSignedInProgram', signedInProgram.data)
              store.dispatch('search/setInitAPIArrived', true)
            })
            .catch(() => {
              store.dispatch('search/setInitAPIArrived', true)
            })
        } else {
          // organization sign in case
          store.dispatch('search/setInitAPIArrived', true)
        }
      } else if (
        [
          ERROR_CODES.TRIPLA_SESSION_TOKEN_PROVIDED_BUT_NOT_FOUND,
          ERROR_CODES.TRIPLA_SESSION_FOUND_BUT_HOTEL_BRAND_MISMATCHED
        ].includes(res?.meta?.reset_code)
      ) {
        if (isTriplabotSyncing) sendDataToParentWindow({ action: 'triplabotSynced' })
        store.dispatch('search/setInitAPIArrived', true)
        store.dispatch('membership/logoutLocally')
        store.dispatch('error/setGlobalError', i18n.t('base.logoutMessage'))
      } else {
        if (isTriplabotSyncing) sendDataToParentWindow({ action: 'triplabotSynced' })
        store.dispatch('search/setInitAPIArrived', true)
        if (getUserToken()) {
          store.dispatch('membership/logoutLocally')
        }
      }

      if (store.getters['setting/getShouldLogoutAfterInitAPIArrived']) {
        store.dispatch('membership/logout')
      }
      return store.getters['setting/getInit']
    })
    .catch((err) => {
      store.dispatch('error/setGlobalError', err.title)
      store.dispatch('search/setInitAPIArrived', true)
    })
}
